@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap"); */

@layer base {
  @font-face {
    font-family: "Monoton";
    src: url("./fonts/Monoton-Regular.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "Poiret One";
    src: url("./fonts/PoiretOne-Regular.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "Ultra";
    src: url("./fonts/Ultra-Regular.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "Asset";
    src: url("./fonts/Asset-Regular.ttf");
    font-display: swap;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

@layer utilities {
  .shallow-text {
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    line-height: inherit;
  }

  .clone {
    pointer-events: none;
    /* color: black; */
    clip-path: inset(0 100% 0 0);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    line-height: inherit;
  }

  /* .clone:hover ~ /clone {
    clip-path: inset(0 0 0 0) !important;
  } */
}

.shallow-text:hover ~ .clone {
  clip-path: inset(0 0 0 0) !important;
}
